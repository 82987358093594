import * as React from "react";
import classNames from "classnames";

const Brand = ({ brand }) => {
  let brandDescription;

  if (brand.includes("Beginning")) {
    brandDescription = (
      <div class="pdp-brand-content">
        <p>
          Beginning Boutique is an Australian online fashion retailer that
          caters to women looking for trendy and affordable clothing. With its
          wide range of fashion-forward products, the company has gained
          popularity both locally and internationally.
        </p>

        <p>
          One of the standout features of Beginning Boutique is its carefully
          curated selection of trendy clothing items, accessories, and shoes.
          From casual everyday outfits to glamorous evening wear, the website
          offers a diverse range of styles to suit every occasion and personal
          preference. Whether customers are searching for a statement piece or
          timeless basics, Beginning Boutique ensures that there is something
          for everyone.
        </p>

        <p>
          The company's website is user-friendly and easy to navigate, allowing
          customers to browse through various categories such as dresses, tops,
          bottoms, swimwear, and accessories. Each product is thoughtfully
          showcased with high-quality images and detailed descriptions, making
          it simple for shoppers to make informed purchasing decisions.
        </p>
        <p>
          Beginning Boutique takes pride not only in its wide selection but also
          in its commitment to sustainability. The company promotes ethical
          fashion by offering eco-friendly products and partnering with
          sustainable brands. This aligns with the growing demand for
          environmentally conscious fashion choices.
        </p>
        <p>
          Additionally, Beginning Boutique has a section on its website called
          "The BB Blog." This serves not only as a platform to showcase the
          latest fashion trends but also as a space for customers to gain
          inspiration and style tips. The blog features articles on a variety of
          topics, including fashion news, beauty, lifestyle, and travel, making
          it a well-rounded source of information for fashion enthusiasts.
        </p>
        <p>
          Customer satisfaction is a top priority for Beginning Boutique. The
          company offers fast and reliable shipping, with domestic orders often
          delivered within just a few business days. There is also a hassle-free
          returns policy in place, ensuring that customers have peace of mind
          when making purchases.
        </p>
        <p>
          In summary, Beginning Boutique is an Australian fashion retailer that
          offers trendy, affordable clothing and accessories for women. With its
          diverse selection, commitment to sustainability, and dedicated blog,
          the company caters to fashion lovers looking for the latest styles and
          inspiration.
        </p>
      </div>
    );
  } else if (brand.includes("Meshki")) {
    brandDescription = (<div class="pdp-brand-content">
      <p>
        Meshki is an Australian-based fashion brand that offers a wide range of
        trendy and fashionable clothing for women. The brand focuses on
        empowering women by providing them with stylish and affordable options
        that help them feel confident and beautiful.
      </p>

      <p>
        When shopping with Meshki, you can expect a vast selection of products,
        including dresses, tops, bottoms, and accessories, all meticulously
        designed to cater to various fashion preferences. The brand prides
        itself on its attention to detail, ensuring that each piece is crafted
        with precision, quality, and style in mind.
      </p>

      <p>
        One of the standout features of Meshki is their ability to keep up with
        the latest fashion trends. The brand is always on top of the game,
        offering customers the hottest styles and designs. Whether you're
        looking for a chic and elegant evening dress or a casual and comfortable
        loungewear set, Meshki has got you covered.
      </p>

      {/* <p>
        Another reason why Meshki is a favorite among fashion enthusiasts is the
        brand's commitment to inclusivity. They understand that women come in
        all shapes and sizes, and therefore, offer a wide range of sizes to suit
        every body type. This inclusivity allows women of different sizes to
        embrace their individuality and find clothing that makes them feel
        confident and beautiful.
      </p>

      <p>
        When it comes to pricing, Meshki is relatively affordable, considering
        the high-quality and fashionable nature of the products. They offer
        regular sales and discounts, making it even more enticing for
        fashion-loving individuals to shop with them.
      </p>

      <p>
        The brand's online shopping platform is user-friendly, with easy
        navigation and detailed product descriptions, allowing shoppers to make
        informed decisions. They offer worldwide shipping, ensuring that women
        from all parts of the globe can enjoy their fashionable collections.
      </p> */}

      <p>
        In summary, Meshki is a popular Australian fashion brand that offers
        trendy and fashionable clothing for women. With their commitment to
        inclusivity, attention to detail, and affordability, shopping with
        Meshki is a great choice for those looking to enhance their wardrobe and
        feel empowered through fashion.
      </p>
    </div>)
  }else if (brand.includes("Vrg") || brand.includes("VRG")) {
    brandDescription = (<div class="pdp-brand-content">
      <p>
      VRG GRL is a vibrant and trendy online fashion store that caters to the style needs of all fashion-conscious individuals. With its extensive collection of stylish clothing, accessories, and footwear, VRG GRL is the ultimate destination for those seeking to elevate their fashion game.
      </p>

      <p>
      One of the standout features of VRG GRL is its commitment to providing fashionable and high-quality products. Each item on the website has been carefully curated, ensuring that you will find only the most trendy and on-trend pieces. Whether you are looking for a chic dress for a special occasion or stylish athleisure wear for a casual day out, VRG GRL has got you covered.
      </p>

      <p>
      VRG GRL also offers a wide range of sizes, ensuring that everyone can find something that fits them perfectly. Whether you're petite or plus-size, they have inclusive sizing options that cater to all body types. This commitment to inclusivity sets VRG GRL apart from many other online fashion retailers.
      </p>      

      <p>
      Another compelling reason to shop at VRG GRL is the affordability of their products. They offer competitive prices without compromising on quality, making it possible for anyone to stay fashionable without breaking the bank. In addition, the website frequently offers sales and discounts, allowing you to snag your favorite items at even lower prices.
      </p>

      <p>      
Furthermore, VRG GRL provides excellent customer service. If you have any queries or concerns, their responsive and friendly customer support team is readily available to assist you. With their expertise and dedication, you can shop confidently knowing that your satisfaction is their priority.
      </p>
    </div>)
  }else if (brand.includes("Princess")) {
    brandDescription = (<div class="pdp-brand-content">
      <p>
      Princess Polly is an online fashion retailer that offers a wide range of trendy and stylish clothing, accessories, and beauty products for young women aged between 18 and 25 years old. With its sleek design and user-friendly interface, the website attracts customers with its constantly updated collection and unique fashion offerings.
      </p>

      <p>
      One of the main attractions of Princess Polly is its wide selection of clothing items that cater to various styles. Whether you're looking for a casual and relaxed outfit or something more elegant for a special occasion, Princess Polly has got it all. The website features a plethora of trendy dresses, tops, bottoms, jumpsuits, and outerwear, ensuring that customers can find the perfect outfit for any occasion.
      </p>

      <p>
      Additionally, Princess Polly offers a diverse range of accessories to complete your look. From jewelry, sunglasses, and hats to bags and shoes, these accessories add an extra touch of style and personality to any outfit. The website also features a comprehensive beauty section, where customers can find skincare and makeup products from popular brands.
      </p>      

      <p>
      Lastly, Princess Polly frequently offers sales, discounts, and promotions, making it an affordable and budget-friendly option for young women. With its constantly changing collection and attractive pricing, Princess Polly keeps customers coming back for more.
      </p>

      
    </div>)
  }else if (brand.includes("Outcast")) {
    brandDescription = (<div class="pdp-brand-content">
      <p>
      Outcast Clothing is an online fashion store that offers a wide range of trendy and unique clothing options for both men and women. With a focus on affordable prices and high-quality products, Outcast Clothing aims to provide customers with a shopping experience that is both enjoyable and convenient.
      </p>

      <p>
      The store features a diverse collection of clothing items, including tops, bottoms, dresses, and accessories. Whether you're looking for casual everyday wear or something more formal for a special occasion, Outcast Clothing has something for everyone. The store also regularly updates its inventory to reflect the latest fashion trends, ensuring that customers will always find something new and exciting.
      </p>

      <p>
      The store offers competitive prices on all its products, making it accessible to a wide range of customers. In addition, Outcast Clothing frequently runs sales and promotions, allowing shoppers to get even more value for their money. This commitment to affordability ensures that customers can update their wardrobe without breaking the bank.

      </p>      

      <p>
      One of the standout features of Outcast Clothing is the emphasis it places on customer satisfaction. The store offers free worldwide shipping on all orders, making it an attractive option for international customers. Additionally, Outcast Clothing provides a hassle-free returns and exchange policy, allowing customers to shop with confidence. 
      </p>

      
    </div>)
  }else if (brand.includes("Petal")) {
    brandDescription = (<div class="pdp-brand-content">
      <p>
      Petal & Pup is an online fashion retailer based in Australia, offering a wide range of trendy clothing and accessories for women. With a user-friendly website and a focus on providing exceptional customer service, Petal & Pup has quickly become a popular destination for fashion-forward shoppers.
      </p>

      <p>
      One of the main reasons why users are drawn to Petal & Pup is the extensive selection of stylish and affordable clothing. The website features a variety of categories, including dresses, tops, skirts, pants, and outerwear, ensuring that there is something to suit every style and occasion. Whether you're looking for a casual everyday outfit or a glamorous evening dress, Petal & Pup has got you covered.
      </p>

      <p>
      Petal & Pup is known for its excellent customer service. The company values the satisfaction of its customers and strives to provide a positive shopping experience. They offer fast shipping, with next-day delivery available in some areas, and free express shipping on orders over a certain amount. Additionally, Petal & Pup has a hassle-free returns policy, allowing customers to easily exchange or return items that don't fit or meet their expectations.

      </p>      

      <p>
      Petal & Pup understands the importance of social media and utilizes platforms like Instagram to engage with their audience. They often collaborate with influencers and provide style inspiration through their feed, making it easier for users to envision how the clothing will look in real life.
      </p>

      
    </div>)
  }else if (brand.includes("Fetch")) {
    brandDescription = (<div class="pdp-brand-content">
      <p>
      That's so Fetch is an online fashion store that offers a wide range of trendy and stylish clothing and accessories for women. With a focus on providing the latest fashion pieces at affordable prices, this Australian-based brand has gained popularity among fashion enthusiasts.
      </p>

      <p>
      Whether you are looking for a chic dress for a special occasion, comfortable loungewear for a relaxed weekend, or fashionable accessories to complete your outfit, you will find it all on their website. They regularly update their collection to keep up with the latest fashion trends, ensuring that their customers have access to the most stylish and up-to-date pieces.
      </p>

      <p>
      That's so Fetch offers competitive pricing for their products. Their aim is to make fashion accessible to all without compromising on style or quality. They often have promotions and discounts, making their already affordable prices even more attractive to users.
      </p>      

      <p>
      That's so Fetch is a popular online fashion store that offers a wide range of trendy and affordable clothing and accessories for women. With their variety of products, quality items, convenient online shopping experience, excellent customer service, and competitive pricing, users have plenty of reasons to choose this brand for their fashion needs.
      </p>

      
    </div>)
  }else if (brand.includes("Supre")) {
    brandDescription = (<div class="pdp-brand-content">
      <p>
      Supre is an online fashion brand that offers a wide range of trendy and affordable clothing and accessories for women. With a focus on empowering young women and helping them express their individual style, Supre has become a popular destination for fashion-forward shoppers.
      </p>

      <p>
      From stylish tops and dresses to comfortable loungewear and activewear, Supre offers a variety of fashionable options to suit different occasions and personal styles. They constantly update their collection to stay on top of the latest trends, ensuring that customers have access to the most up-to-date fashion pieces.
      </p>

      <p>
      In addition to their product range and quality, Supre focuses on providing a seamless and convenient shopping experience for customers. Their user-friendly website allows users to easily browse through their collection, find their desired items, and make secure purchases. They also offer various payment options and provide fast and reliable shipping, ensuring that customers receive their orders promptly.
      </p>      

      <p>
      They have a dedicated customer support team that is readily available to address any queries or concerns. Whether it's sizing, returns, or general inquiries, Supre strives to provide excellent customer service and ensure a positive shopping experience.
      </p>

      
    </div>)
  }else if (brand.includes("Hello")) {
    brandDescription = (<div class="pdp-brand-content">
      <p>
      HelloMolly is an Australian online fashion retailer that offers a wide range of trendy and fashionable clothing for women. With their website, au.hellomolly.com, they aim to provide customers with a unique shopping experience by offering a variety of styles that cater to different tastes and preferences.
      </p>

      <p>
      The website features a user-friendly interface that allows customers to easily navigate through different categories such as dresses, tops, bottoms, and accessories. HelloMolly prides itself on staying ahead of the fashion curve and regularly updating its collections to ensure customers have access to the latest trends.
      </p>

      <p>
      One of the key highlights of HelloMolly is its extensive collection of dresses. From casual day dresses to glamorous evening gowns, customers can find something to suit every occasion. The company focuses on quality and offers a range of sizes to ensure a perfect fit for all body types.
      </p>      

      <p>
      HelloMolly frequently offers discounts and promotions, making it affordable for customers to stay on-trend without breaking the bank. They also provide fast and reliable shipping options, ensuring that customers receive their orders in a timely manner.
      </p>

      
    </div>)
  }else if (brand.includes("Forever")) {
    brandDescription = (<div class="pdp-brand-content">
      <p>
      Forever New is a women's fashion brand that was founded in Melbourne, Australia in 2006. The brand aims to create timeless and sophisticated designs for its customers, offering a wide range of clothing, accessories, and footwear.
      </p>

      <p>
      Forever New focuses on femininity, elegance, and attention to detail in its collections, catering to women who have a love for fashion and desire high-quality garments. The brand's designs incorporate a mix of classic and contemporary styles, incorporating trends and incorporating its unique aesthetic.
      </p>

      <p>
      The brand is known for its use of soft and feminine colors, delicate prints, and intricate embellishments. Forever New offers a variety of clothing options for different occasions, from casual everyday wear to formal evening dresses. The brand also specializes in wedding and bridesmaid dresses, allowing customers to find the perfect outfit for their special day.
      </p>      

      <p>
      Forever New emphasizes sustainability and ethical practices in its production processes. The brand is committed to reducing its environmental impact by using eco-friendly materials and adopting responsible sourcing methods.
      </p>

      <p>
      With a strong online presence and numerous physical stores worldwide, Forever New has gained popularity among women globally. The brand's focus on timeless elegance, quality craftsmanship, and environmental consciousness has solidified its position as a leading fashion brand in the industry.
      </p>

      
    </div>)
  }else if (brand.includes("Twosisters")) {
    brandDescription = (<div class="pdp-brand-content">
      <p>
      Two Sisters the Label is an Australian fashion brand that was founded by sisters Tan and Gee in 2013. The brand aims to empower women by creating trendy and affordable clothing options that cater to a diverse range of body types and styles.
      </p>

      <p>
      Two Sisters the Label offers a wide range of clothing items, including dresses, tops, bottoms, and outerwear. The brand is known for its feminine and chic designs, incorporating elements such as floral prints, lace details, and flattering silhouettes. The clothing is designed to be versatile, allowing customers to easily transition from day to night or from casual to formal occasions.
      </p>

      <p>
      In addition to its ready-to-wear collections, Two Sisters the Label also offers a selection of accessories, including belts, handbags, and jewelry, allowing customers to complete their looks with stylish and complementary pieces.
      </p>      

      <p>
      The brand places a strong emphasis on affordability, making fashion accessible to a wide range of customers. Despite its affordable price point, Two Sisters the Label maintains a high standard of quality, ensuring that its products are made to last.
      </p>

      <p>
      With a strong online presence and numerous stockists worldwide, Two Sisters the Label has gained a loyal following of customers who appreciate the brand's trendy designs, affordability, and inclusive sizing options. The brand continues to grow and evolve, constantly releasing new collections that reflect the latest fashion trends while staying true to its mission of empowering women through stylish and affordable fashion.
      </p>

      
    </div>)
  }


  return (
    <div id="pdp-brand-container" className="pt-4 pb-0">
      {brandDescription}
    </div>
  );
};

export default Brand;
